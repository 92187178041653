import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import Box from '@mui/material/Box';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { fontWeight, Text, textLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { positionType, Toggle } from '@confidant-health/lib/ui/atoms/toggle';
import { UploadFile } from '@confidant-health/lib/ui/templates/upload-file';
import { S3_BUCKET_URL } from '@confidant-health/lib/constants/CommonConstants';
import dayjs, { Dayjs } from 'dayjs';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { stateSelector } from 'redux/modules/state/selectors';
import DatePickerInput from 'pages/admin/claim-detail/components/DatePicker';
import { getProfile } from 'redux/modules/profile/selectors';
import { stateActionCreators } from 'redux/modules/state';
import { IDemographicDetails } from 'redux/modules/profile/types';

import UploadFileProgress from '../upload-flie-progress';
import { IInsuranceInformation } from '../index.types';
import { useStyles } from '../../../MemberDetail.styles';
import { RELATIONSHIPS_TO_CLIENTS, RELATIONSHIPS_TO_CLIENTS_ENUM, GENDERS } from '../constants';
import { GLOBAL_DATE_FORMAT } from '../../../../../../constants/CommonConstants';

type Props = {
  states?: any[];
  data: any;
  openDrawer: boolean;
  onClose: () => void;
  vbc?: boolean;
  patientInfo?: IDemographicDetails;
};

const InsuranceInformationEdit = ({ states, data, openDrawer, onClose, vbc, patientInfo }: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { payers, isLoading } = useSelector(stateSelector);
  const { selectedPatient } = useSelector(getProfile);
  const [memberIdPhotoFront, setMemberIdPhotoFront] = useState(null);
  const [memberIdPhotoFrontInfo, setMemberIdPhotoFrontInfo] = useState(null);
  const [memberIdPhotoBack, setMemberIdPhotoBack] = useState(null);
  const [memberIdPhotoBackInfo, setMemberIdPhotoBackInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValid, setFormValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<Partial<IInsuranceInformation>>({
    memberId: null,
    employerGroupId: null,
    insurancePlan: null,
    insuranceCarrier: null,
    selectedState: null,
  });
  const [formData, setFormData] = useState<IInsuranceInformation>({
    firstName: '',
    lastName: '',
    fullName: '',
    selectedState: '',
    insuranceCarrier: '',
    insurancePlan: '',
    primaryInsured: true,
    relationshipToPrimarySubscriber: '',
    memberId: '',
    employerGroupId: '',
    isEnabled: true,
    isCopay: false,
    coPayAmount: 1,
    gender: '',
    employerName: '',
    coPayAmountError: '',
    vbcFlag: false,
  });

  const gendersOptions = GENDERS?.map((r, i) => ({
    label: GENDERS[i],
    value: r,
  }));
  useEffect(() => {
    if (formData?.selectedState || data?.currentInsurance?.stateId) {
      dispatch(stateActionCreators.fetchPayers(formData?.selectedState || data?.currentInsurance?.stateId));
    }
  }, [formData?.selectedState]);

  useEffect(() => {
    setFormData({
      ...formData,
      insuranceCarrier: payers?.find(pay => pay?._id === data?.currentInsurance?._id)?._id || '',
    });
  }, [payers]);

  useEffect(() => {
    setFormData({
      ...formData,
      firstName: data?.patientInsuranceData?.firstName
        ? data?.patientInsuranceData?.firstName
        : patientInfo?.firstName || '',
      lastName: data?.patientInsuranceData?.lastName
        ? data?.patientInsuranceData?.lastName
        : patientInfo?.lastName ?? '',
      fullName: data?.patientInsuranceData?.fullName ?? '',
      selectedState: states?.find(state => state?._id === data?.currentInsurance?.stateId)?._id ?? '',
      insuranceCarrier: payers?.find(pay => pay?._id === data?.currentInsurance?._id)?._id ?? '',
      insurancePlan: data?.patientInsuranceData?.planId ?? '',
      primaryInsured: data !== null ? data?.patientInsuranceData?.primaryInsured : true,
      relationshipToPrimarySubscriber: data?.patientInsuranceData?.relationshipToPrimarySubscriber
        ? data?.patientInsuranceData?.relationshipToPrimarySubscriber
        : '',
      memberId: data?.patientInsuranceData?.memberId,
      employerGroupId: data?.patientInsuranceData?.employerGroupId ?? '',
      isEnabled: data !== null ? data?.patientInsuranceData?.isEnabled : true,
      isCopay: data?.isCopay || false,
      coPayAmount: data?.coPayAmount || 1,
      dob: getDoB() ?? '',
      gender: data?.patientInsuranceData?.gender
        ? data?.patientInsuranceData?.gender?.toUpperCase()
        : patientInfo?.sex,
      employerName: data?.patientInsuranceData?.employerName ?? '',
      vbcFlag: vbc || false,
    });
    setMemberIdPhotoFront(data?.patientInsuranceData?.memberIdPhotoFront);
    setMemberIdPhotoBack(data?.patientInsuranceData?.memberIdPhotoBack);
    setLoading(false);
  }, []);
  function getDoB() {
    let { dob } = formData;
    if (!formData?.dob || formData?.dob === '') {
      if (data?.patientInsuranceData?.dob && data?.patientInsuranceData?.dob !== '') {
        dob = data.patientInsuranceData.dob;
      } else if (patientInfo?.dateOfBirth && patientInfo.dateOfBirth !== '') {
        dob = patientInfo.dateOfBirth;
      }
    }
    return dayjs(dob).format(GLOBAL_DATE_FORMAT);
  }
  useEffect(() => {
    if (formData?.primaryInsured) {
      setFormData(old => ({
        ...old,
        firstName: old?.firstName === '' ? patientInfo?.firstName || '' : old?.firstName,
        lastName: old?.lastName === '' ? patientInfo?.lastName || '' : old?.lastName,
        dob: getDoB(),
      }));
    }
  }, [formData?.primaryInsured]);
  const onChangeField = (field: string) => (e: any) => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: null }));
    }
    setFormData(old => ({
      ...old,
      [field]:
        field === 'primaryInsured'
          ? !old.primaryInsured
          : field === 'isEnabled'
          ? !old.isEnabled
          : field === 'isCopay'
          ? !old?.isCopay
          : field === 'vbcFlag'
          ? !old?.vbcFlag
          : e.target?.value,
    }));
  };

  /**
   * @Name onAcceptedFilesFront
   * @description This method is used when we select/accept Front
   */
  const onAcceptedFilesFront = (files: File[]) => {
    const file = files[0];
    setMemberIdPhotoFront(file);
  };

  /**
   * @Name onAcceptedFilesBack
   * @description This method is used when we select/accept Front
   */
  const onAcceptedFilesBack = (files: File[]) => {
    const file = files[0];
    setMemberIdPhotoBack(file);
  };

  const handleSubmit = () => {
    if (formData?.primaryInsured) {
      setErrors({
        selectedState:
          !formData?.selectedState || formData?.selectedState?.trim().length === 0
            ? 'Please select state'
            : null,
        insuranceCarrier:
          !formData?.insuranceCarrier || formData?.insuranceCarrier?.trim()?.length === 0
            ? 'Please select insurance carrier'
            : null,
        insurancePlan:
          !formData?.insurancePlan || formData?.insurancePlan?.trim()?.length === 0
            ? 'Please select insurance plan'
            : null,
        fullName:
          !formData?.fullName || formData?.fullName?.trim()?.length === 0 ? 'Full Name is required' : null,
        dob: !formData?.dob || formData?.dob?.trim().length === 0 ? 'DOB is required' : null,
        memberId:
          !formData?.memberId || formData?.memberId?.trim()?.length === 0 ? 'Member Id is required' : null,
        coPayAmountError:
          formData?.isCopay && formData?.coPayAmount < 1 ? 'Copay amount must be greater than 1' : null,
      });
    }
    if (!formData?.primaryInsured) {
      setErrors({
        selectedState:
          !formData.selectedState || formData.selectedState?.trim().length === 0
            ? 'Please select state'
            : null,
        insuranceCarrier:
          !formData.insuranceCarrier || formData.insuranceCarrier?.trim().length === 0
            ? 'Please select insurance carrier'
            : null,
        insurancePlan:
          !formData.insurancePlan || formData.insurancePlan?.trim().length === 0
            ? 'Please select insurance plan'
            : null,
        fullName: !formData?.fullName || formData.fullName?.trim().length === 0 ? 'Name is required' : null,
        dob: !formData?.dob || formData.dob?.trim().length === 0 ? 'DOB is required' : null,
        relationshipToPrimarySubscriber:
          !formData?.relationshipToPrimarySubscriber ||
          formData?.relationshipToPrimarySubscriber?.trim().length === 0
            ? 'Relationship value is required'
            : null,
        memberId:
          !formData?.memberId || formData?.memberId?.trim().length === 0 ? 'Member Id is required' : null,
        coPayAmountError:
          formData.isCopay && formData.coPayAmount < 1 ? 'Copay amount must be greater than 1' : null,
      });
    }
    if (!formData?.selectedState || !formData.insuranceCarrier) {
      return;
    }
    // const photos = await uploadImages();
    const photoFront = memberIdPhotoFront || data?.patientInsuranceData?.memberIdPhotoFront;
    const photoBack = memberIdPhotoBack || data?.patientInsuranceData?.memberIdPhotoBack;
    const dob = formData?.dob
      ? dayjs(formData.dob).format('YYYY-MM-DD')
      : selectedPatient?.dob
      ? dayjs(selectedPatient.dob).format('YYYY-MM-DD')
      : '';
    const payload = {
      memberId,
      payload: {
        userAccountId: memberId,
        currentInsurance: formData?.insuranceCarrier,
        status: data?.status,
        rejectionNotes: data?.rejectionNotes,
        isCopay: formData?.isCopay,
        vbcFlag: formData?.vbcFlag ? 'ACTIVE' : 'INACTIVE',
        coPayAmount: formData?.isCopay ? formData?.coPayAmount : 1,
        patientInsuranceData: {
          primaryInsured: formData?.primaryInsured,
          fullName:
            formData.firstName && formData.lastName
              ? `${formData.firstName} ${formData.lastName}`
              : selectedPatient?.member?.firstName && selectedPatient?.member?.lastName
              ? `${selectedPatient.member.firstName} ${selectedPatient.member.lastName}`
              : formData?.fullName || selectedPatient?.member?.fullName,
          firstName: formData?.firstName || selectedPatient?.member?.firstName,
          lastName: formData?.lastName || selectedPatient?.member?.lastName,
          dob, // formData?.dob || selectedPatient?.dob || '',
          memberId: formData?.memberId,
          gender: formData?.gender || '',
          memberIdPhotoFront: photoFront ? photoFront.replaceAll(S3_BUCKET_URL, '') : '',
          memberIdPhotoBack: photoBack ? photoBack.replaceAll(S3_BUCKET_URL, '') : '',
          relationshipToPrimarySubscriber: formData?.relationshipToPrimarySubscriber || 'OTHER',
          employerName: formData?.employerName || '',
          employerGroupId: formData?.employerGroupId,
          isEnabled: formData?.isEnabled,
          planId: formData?.insurancePlan,
        },
      },
    };
    if (!data) {
      dispatch(profileActionCreators.addMemberInsurance(payload));
    } else {
      dispatch(profileActionCreators.updateMemberInsurance(payload));
    }
    setTimeout(() => {
      dispatch(profileActionCreators.fetchMemberInsurance(payload));
      dispatch(profileActionCreators.fetchDemographicDetails(memberId));
    }, 2000);
    onClose();
  };

  function onChangeDateOfBirth(date: Dayjs) {
    if (date?.isValid() && date?.isBefore(dayjs())) {
      setFormData({ ...formData, dob: date.format(GLOBAL_DATE_FORMAT) });
      setFormValid(true);
    } else {
      setFormData({ ...formData, dob: '' });
      setFormValid(false);
    }
  }

  return (
    !loading && (
      <>
        <Drawer
          variant={drawerType.FORM}
          position={drawerPosition.RIGHT}
          open={openDrawer}
          onClose={onClose}
          title="Insurance information"
          submitBtnTitle="Save"
          disableSubmit={!formValid}
          onSubmit={handleSubmit}
        >
          <Box className={classes.inputsContainer}>
            <Box className={classes.inputWrapper}>
              <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                <span>State</span>
              </Text>
              <Select
                variant={selectType.SECONDARY}
                value={formData?.selectedState}
                onChange={onChangeField('selectedState')}
                options={states?.map(state => {
                  return { label: state?.name, value: state?._id };
                })}
              />
              <TextError errorMsg={errors.selectedState} />
            </Box>
            {isLoading ? (
              <>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                Loading...
              </>
            ) : (
              <>
                <Box className={classes.inputWrapper}>
                  <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                    <span>Insurance carrier</span>
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    value={formData?.insuranceCarrier}
                    onChange={onChangeField('insuranceCarrier')}
                    options={payers
                      ?.filter(payer => payer.isActive)
                      .map(payer => {
                        return { label: payer?.name, value: payer?._id };
                      })}
                  />
                  <TextError errorMsg={errors.insuranceCarrier} />
                </Box>
                <Box className={classes.inputWrapper}>
                  <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                    <span>Insurance plan</span>
                  </Text>
                  <Select
                    variant={selectType.SECONDARY}
                    value={formData?.insurancePlan}
                    onChange={onChangeField('insurancePlan')}
                    options={payers
                      ?.find(paye => paye?._id === formData.insuranceCarrier)
                      ?.plans?.map(plan => {
                        return { label: plan?.name, value: plan?._id };
                      })}
                    disabled={!formData?.insuranceCarrier}
                  />
                  <TextError errorMsg={errors.insurancePlan} />
                </Box>
                <Box className={classes.inputWrapper}>
                  <Toggle
                    labelPosition={positionType.RIGHT}
                    checked={formData.vbcFlag}
                    onChange={onChangeField('vbcFlag')}
                  >
                    VBC
                  </Toggle>
                </Box>
              </>
            )}
            <Box className={classes.inputWrapper}>
              <Toggle
                labelPosition={positionType.RIGHT}
                checked={formData.primaryInsured}
                onChange={onChangeField('primaryInsured')}
              >
                Member is the primary insured
              </Toggle>
            </Box>
            <>
              <Box className={classes.inputWrapper}>
                <label htmlFor="firstName">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    {`${!formData.primaryInsured ? 'Primary Insured First Name' : 'First Name'}`}
                  </Text>
                </label>
                <Input
                  name="firstName"
                  value={formData.firstName || ''}
                  onChange={onChangeField('firstName')}
                  size={inputSize.M}
                  placeholder="Enter first name"
                />
                <TextError errorMsg={errors.firstName} />
              </Box>
              <Box className={classes.inputWrapper}>
                <label htmlFor="lastName">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    {`${!formData.primaryInsured ? 'Primary Insured Last Name' : 'Last Name'}`}
                  </Text>
                </label>
                <Input
                  name="lastName"
                  value={formData.lastName || ''}
                  onChange={onChangeField('lastName')}
                  size={inputSize.M}
                  placeholder="Enter last name"
                />
                <TextError errorMsg={errors.lastName} />
              </Box>
              <Box className={classes.inputWrapper}>
                <label htmlFor="dob">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    {`${!formData.primaryInsured ? 'Primary Insured DOB' : 'DOB'}`}
                  </Text>
                </label>
                <DatePickerInput date={dayjs(formData.dob)} onChange={date => onChangeDateOfBirth(date)} />
                <TextError errorMsg={errors.dob} />
              </Box>
            </>
            {!formData?.primaryInsured && (
              <Box className={classes.inputWrapper}>
                <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                  <span>Relationship to primary insured</span>
                </Text>
                <Select
                  variant={selectType.SECONDARY}
                  value={formData.relationshipToPrimarySubscriber}
                  onChange={onChangeField('relationshipToPrimarySubscriber')}
                  options={RELATIONSHIPS_TO_CLIENTS?.map(r => ({
                    label: RELATIONSHIPS_TO_CLIENTS_ENUM[r],
                    value: r,
                  }))}
                />
                <TextError errorMsg={errors.relationshipToPrimarySubscriber} />
              </Box>
            )}

            <Box className={classes.inputWrapper}>
              <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Gender</span>
              </Text>
              <Select
                variant={selectType.SECONDARY}
                value={formData.gender}
                onChange={onChangeField('gender')}
                options={gendersOptions}
              />
              <TextError errorMsg={errors?.gender} />
            </Box>
            <Box className={classes.inputWrapper}>
              <label htmlFor="member__id">
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  Member ID
                </Text>
              </label>
              <Input
                name="memberId"
                value={formData.memberId || ''}
                onChange={onChangeField('memberId')}
                size={inputSize.M}
                placeholder="Enter your member ID"
              />
              <TextError errorMsg={errors.memberId} />
            </Box>
            {!formData?.primaryInsured && (
              <Box className={classes.inputWrapper}>
                <label htmlFor="employerName">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Employer Name
                  </Text>
                </label>
                <Input
                  name="employerName"
                  value={formData.employerName || ''}
                  onChange={onChangeField('employerName')}
                  size={inputSize.M}
                  placeholder="Enter your employer name"
                />
                <TextError errorMsg={errors.employerName} />
              </Box>
            )}
            {!formData?.primaryInsured && (
              <Box className={classes.inputWrapper}>
                <label htmlFor="group__id">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Employer Group ID
                  </Text>
                </label>
                <Input
                  name="employerGroupId"
                  value={formData.employerGroupId || ''}
                  onChange={onChangeField('employerGroupId')}
                  size={inputSize.M}
                  placeholder="Enter your group ID"
                />
                <TextError errorMsg={errors.employerGroupId} />
              </Box>
            )}

            <Box className={classes.inputWrapper}>
              <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Insurance Card Front</span>
              </Text>
              {memberIdPhotoFront ? (
                <UploadFileProgress
                  file={memberIdPhotoFront}
                  fileName={memberIdPhotoFrontInfo?.name || 'logo.png'}
                  fileSize={memberIdPhotoFrontInfo?.size || 1024}
                  s3Folder="idCardImages/"
                  onUploaded={(url, file) => {
                    setMemberIdPhotoFront(url);
                    setMemberIdPhotoFrontInfo(file);
                  }}
                  onRemove={() => {
                    setMemberIdPhotoFront(null);
                  }}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={e => {
                    onAcceptedFilesFront(e);
                  }}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
            </Box>
            <Box className={classes.inputWrapper}>
              <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
                <span>Insurance Card Back</span>
              </Text>
              {memberIdPhotoBack ? (
                <UploadFileProgress
                  file={memberIdPhotoBack}
                  fileName={memberIdPhotoBackInfo?.name || 'logo.png'}
                  fileSize={memberIdPhotoBackInfo?.size || 1024}
                  s3Folder="idCardImages/"
                  onUploaded={(url, file) => {
                    setMemberIdPhotoBack(url);
                    setMemberIdPhotoBackInfo(file);
                  }}
                  onRemove={() => {
                    setMemberIdPhotoBack(null);
                  }}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={e => {
                    onAcceptedFilesBack(e);
                  }}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
            </Box>
            <Box className={classes.inputWrapper}>
              <Toggle
                labelPosition={positionType.RIGHT}
                checked={formData?.isCopay}
                onChange={onChangeField('isCopay')}
              >
                Is Copay
              </Toggle>
            </Box>
            {formData?.isCopay && (
              <Box className={classes.inputWrapper}>
                <label htmlFor="coPayAmount">
                  <Text level={textLevel.S} weight={fontWeight.BOLD}>
                    Copay Amount
                  </Text>
                </label>
                <Input
                  name="coPayAmount"
                  value={formData?.coPayAmount}
                  onChange={onChangeField('coPayAmount')}
                  size={inputSize.M}
                  placeholder="Enter copay Amount"
                />
                <TextError errorMsg={errors.coPayAmountError} />
              </Box>
            )}
            <Box className={classes.inputWrapper}>
              <Toggle
                labelPosition={positionType.RIGHT}
                checked={formData?.isEnabled}
                onChange={onChangeField('isEnabled')}
              >
                Enabled
              </Toggle>
            </Box>
          </Box>
        </Drawer>
      </>
    )
  );
};

export default InsuranceInformationEdit;
