/* eslint-disable jsx-a11y/aria-role */
import { Box, Grid } from '@mui/material';
import { iconBtnPosition, iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { useSelector } from 'react-redux';
import history from 'utils/history';
import { getAuth } from 'redux/modules/auth/selectors';
import ProfileInfo from 'components/profile-info';
import { useStyles } from './Appointments.styles';
import dayjs, { getUserTimeZone } from '../../../../utils/dayjs';
import { IAssignedEvaluation } from '../../../../redux/modules/conversation/types';

type Props = {
  isPast?: boolean;
  onSelectAssignedEvaluation?: (evaluation: IAssignedEvaluation) => void;
  evaluations?: IAssignedEvaluation[];
  onSeeAll?: () => void;
  appointment?: IAppointment;
  isWaiting?: boolean;
  title?: string;
  viewApptDetail?: (appt: IAppointment | null) => void;
  showViewDetails?: boolean;
  hideCardHeader?: boolean;
};

const AppointmentCard = ({
  isWaiting,
  isPast,
  onSelectAssignedEvaluation,
  onSeeAll,
  appointment,
  evaluations,
  title = '',
  viewApptDetail,
  hideCardHeader = false,
}: Props) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const providerSchedule = useSelector(selectProviderSchedule);

  const statusText =
    appointment.status === 'BOOKED'
      ? 'Scheduled'
      : appointment.status === 'FULFILLED'
      ? 'Completed'
      : 'Cancelled';

  const colorClass =
    appointment.status === 'BOOKED' ? 'primary' : appointment.status === 'FULFILLED' ? 'success' : 'neutral';

  const showApptDetails = () => {
    if (viewApptDetail) {
      if (isPast) {
        history.push(
          `${isAdmin ? '/admin' : '/provider'}/members/${appointment.patientId}/appointments/${
            appointment.appointmentId
          }`,
        );
      } else viewApptDetail(appointment);
    }
  };

  return (
    <Box className={classes.apptCard}>
      {!hideCardHeader && (
        <Box className={classes.top}>
          {title ? (
            <Box className={classes.text18}>{title}</Box>
          ) : (
            <Box className={classes.text18}>{isPast ? 'Last appointment' : 'This appointment'}</Box>
          )}
          {evaluations?.length > 0 && (
            <Box sx={{ svg: { marginLeft: 1 }, '& button': { fontSize: 14 } }}>
              <IconButton
                onClick={onSeeAll}
                icon="arrow-right"
                iconPosition={iconBtnPosition.RIGHT}
                variant={iconBtnType.TEXT}
              >
                {isPast ? 'All past evaluations' : 'View assigned evaluations'}
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      <Box className={classes.mid}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          onClick={showApptDetails}
          sx={{
            cursor: viewApptDetail ? 'pointer' : 'default',
          }}
        >
          <Grid item xs={6} md={isWaiting === false ? 6 : 3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Box className={classes.text16}>
                {dayjs(appointment.startTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('MMMM DD, YYYY')}
              </Box>
              <Box className={classes.text16Second}>
                {`${dayjs(appointment.startTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('hh:mma')} - ${dayjs(appointment.endTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('hh:mma')}`}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={isWaiting === false ? 6 : 3}
            display="flex"
            sx={{
              justifyContent: isWaiting === false ? { xs: 'flex-end' } : { xs: 'flex-end', md: 'flex-start' },
            }}
          >
            <Box>
              <ProfileInfo
                userRole={appointment.practitionerDesignation}
                fullName={appointment.practitionerName}
                src={appointment.practitionerImage}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={isWaiting === false ? 6 : 4}>
            <Box className={classes.text16}>{appointment?.serviceName}</Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={isWaiting === false ? 6 : 2}
            display="flex"
            sx={{ justifyContent: isWaiting === false ? 'flex-end' : { xs: 'flex-end' } }}
          >
            <Button className={`${classes.btn} ${colorClass}`}>{statusText}</Button>
          </Grid>
        </Grid>
      </Box>
      {evaluations &&
        evaluations?.map((evaluation, index) => {
          const status =
            evaluation.status === 'NOT_STARTED'
              ? 'Assigned'
              : evaluation.status === 'IN_PROGRESS'
              ? 'In progress'
              : evaluation.status === 'REJECTED'
              ? 'Rejected'
              : evaluation.status === 'INCOMPLETE'
              ? 'Incomplete'
              : 'Completed';
          const statusBadgeStyle =
            evaluation.status === 'NOT_STARTED'
              ? badgeStyle.INACTIVE
              : evaluation.status === 'IN_PROGRESS' || evaluation.status === 'INCOMPLETE'
              ? badgeStyle.PRIMARY
              : evaluation.status === 'REJECTED'
              ? badgeStyle.CRITICAL
              : badgeStyle.SUCCESS;
          const { totalCb, totalExercises, totalRequiredCb, forProviderCount, unanswered } =
            evaluation?.evaluationStats ?? {
              totalCb: 0,
              totalExercises: 0,
              totalRequiredCb: 0,
              forProviderCount: 0,
              unanswered: 0,
            };
          return (
            <Box
              key={index}
              className={classes.evaluation}
              onClick={() => onSelectAssignedEvaluation(evaluation)}
            >
              <Grid container>
                <Grid item xs={12} md={isWaiting === false ? 12 : 6}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: isWaiting === false ? 'space-between' : 'inherit',
                    }}
                  >
                    <Box maxWidth="220px" className={classes.text14}>
                      {evaluation.name}
                    </Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={statusBadgeStyle}>
                      {status}
                    </Badge>
                  </Box>
                </Grid>
                {isWaiting && (
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 3,
                        justifyContent: 'flex-end',
                        width: '100%',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Box className={classes.text14}>All</Box>
                        <Box>
                          <Badge
                            className={classes.badge}
                            variant={badgeType.OUTLINED}
                            style={badgeStyle.INACTIVE}
                          >
                            {totalCb}
                          </Badge>
                        </Box>
                      </Box>
                      {totalRequiredCb > 0 && (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Box className={classes.text14}>Required</Box>
                          <Box>
                            <Badge
                              className={classes.badge}
                              variant={badgeType.OUTLINED}
                              style={badgeStyle.INACTIVE}
                            >
                              {totalRequiredCb}
                            </Badge>
                          </Box>
                        </Box>
                      )}
                      {unanswered > 0 &&
                        (evaluation.status === 'COMPLETED' || evaluation.status === 'IN_PROGRESS') && (
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Box className={classes.text14}>Unanswered</Box>
                            <Box>
                              <Badge
                                className={classes.badge}
                                variant={badgeType.OUTLINED}
                                style={badgeStyle.INACTIVE}
                              >
                                {unanswered}
                              </Badge>
                            </Box>
                          </Box>
                        )}
                      {forProviderCount > 0 && (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Box className={classes.text14}>For provider</Box>
                          <Box>
                            <Badge
                              className={classes.badge}
                              variant={badgeType.OUTLINED}
                              style={badgeStyle.INACTIVE}
                            >
                              {forProviderCount}
                            </Badge>
                          </Box>
                        </Box>
                      )}
                      {totalExercises > 0 && (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Box className={classes.text14}>Exercises</Box>
                          <Box>
                            <Badge
                              className={classes.badge}
                              variant={badgeType.OUTLINED}
                              style={badgeStyle.INACTIVE}
                            >
                              {totalExercises}
                            </Badge>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          );
        })}
    </Box>
  );
};

export default AppointmentCard;
