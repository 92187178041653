// types

import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { IPayerGroup } from '../state/types';

export interface IPatient {
  member: {
    id: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    nickName?: string;
    photo: string;
    uuid?: string;
  };
  id?: string;
  state?: string;
  email: string;
  dob: string;
  phone: string;
  completed: number;
  fundsInWallet: number;
  insurance?: string;
  uuid?: string;
  engagement?: {
    Status?: string;
    SubStatus?: string;
  };

  [key: string]: any;
}

export type IProfile = {
  firstName: string;
  lastName: string;
  gender: string;
  emailAddress: string;
  fullName: string;
  bio: string;
  designation: string;
  defaultOrganizationId: string;
  defaultOrganizationName: string;
  profileImage: string;
  branchLink?: string;
  phoneNumber: string;
  address: string;
  active: boolean;
  matchmaker: boolean;
  matchmakerAutoConnection: boolean;
  providerCode: string;
  passwordResetRequired: boolean;
  speciality: string[];
  paymentRole: string;
  operatingStates: string[];
  insurances?: IPayerGroup[];
  stateLimited: boolean;
  signOffRole: string;
  requireSupervisorSignOff: boolean;
  supervises: {
    providerId: string;
    fullName: string;
    designation: string;
    address: string;
  }[];
  providerId: string;
  providerProfile: {
    profilePicture?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    credentials: string[];
    certifications: string[];
    employmentPlace: string[];
    employmentName: string[];
    employmentStartDate: string;
    employmentEndDate: string;
    educationName: string[];
    educationPlace: string[];
    educationStartDate: string;
    educationEndDate: string;
    educationDescription: string;
    providerId: string;
    educationImage: string;
    employmentImage: string;
    affiliationName: string;
    affiliationImage: string;
    affiliationPlace: string;
    providerRole: string;
    rating: null | number;
    reviews: any[] | null;
    philosophy: string;
    providerImages: string[];
    backstory: string;
    training: string;
    approach?: string;
    connectionsList?: string;
    affiliationStartDate?: string;
    affiliationEndDate?: string;
  };
  approach: string;
  connectionsList: string[] | null;
  connectionsInLast30Days?: string;
  appointmentsList?: string[] | null;
  appointmentsInLast30Days?: string;
  providerSequence: number;
  npi?: string;
};

export interface AppointmentStats {
  referred: string;
  startedCare: string;
  totalAppointments: string;
  appointmentsThisMonth: string;
}
export interface IDemographicDetails {
  address1: string;
  address2: string;
  careTeam: string[];
  city: string;
  dateOfBirth: string;
  editableFields: string[];
  electronicCommunication: string;
  email: string;
  emergencyContact: string;
  emergencyPhone: string;
  firstName: string;
  hipaa: string;
  lastName: string;
  fullName: string;
  idCardFront: string;
  idCardBack: string;
  matchmakers: [];
  mobilePhone: string;
  nickName: string;
  levelOfEngagement: ILevelOfEngagement;
  levelOfEngagementStatus?: string;
  levelOfEngagementSubStatus?: string;
  visitFrequency?: string;
  isActiveChat: boolean;
  isConnected?: boolean;
  referralSources?: string[];
  referralSource?: string;
  notInAcuteWithdrawals: string;
  notSuicidal: string;
  onboardingGoals: string[];
  pcpRelease: string;
  uuid: string;
  privacyPolicy: string;
  profilePicture?: string;
  sex: string;
  state: string;
  terms: string;
  zip: string;
  genderPronoun?: string;
  genderIdentity?: string;
  revampReward?: string;
  appointmentStats?: AppointmentStats;
  referralPartners?: any[];
  vbcFlag?: boolean;
  eprescribeEntry?: boolean;
}

export interface IUserGroup {
  attendanceCount: number;
  channelUrl: string;
  groupImage: string;
  joinedAt: string;
  name: string;
  meetings: any[];
}

export interface IProvider {
  id: string;
  active: boolean;
  acceptingNewPatients: boolean;
  address: string;
  name?: string;
  appointmentsInLast30Days: null;
  appointmentsList: any;
  approach: string;
  bio: null;
  branchLink: null;
  connectionsInLast30Days: null;
  providerAvailabilityCalendar?: string;
  connectionsList: {
    name: string;
    date: string;
    profilePicture?: string;
    totalSessions: number;
    totalPayments: number;
    totalSchedule?: number;
    designation?: string;
    connectionId?: string;
  }[];
  defaultOrganizationId: string;
  defaultOrganizationName: string;
  designation: string;
  emailAddress: string;
  firstName: string;
  fullName: string;
  gender: string;
  lastName: string;
  matchmaker: boolean;
  matchmakerAutoConnection: boolean;
  operatingStates: string[];
  insurances: IPayerGroup[];
  passwordResetRequired: boolean;
  paymentRole: null;
  phoneNumber: null;
  profileImage: null;
  providerCode: string;
  providerId: string;
  uuid?: string;
  providerProfile: {
    profilePicture?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    credentials: string[];
    certifications: string[];
    employmentPlace: string[];
    employmentName: string[];
    employmentStartDate: string;
    employmentEndDate: string;
    educationName: string[];
    educationPlace: string[];
    educationStartDate: string;
    educationEndDate: string;
    educationDescription: string;
    providerId: string;
    educationImage: string;
    employmentImage: string;
    affiliationName: string;
    affiliationImage: string;
    affiliationPlace: string;
    providerRole: string;
    rating: null | number;
    reviews: any[] | null;
    philosophy: string;
    providerImages: string[];
    backstory: string;
    training: string;
    approach?: string;
    connectionsList?: string;
    affiliationStartDate?: string;
    affiliationEndDate?: string;
  };
  schedule: {
    actor: string;
    slots: Array<{
      start: string;
      end: string;
    }>;
  };
  providerSequence: number;
  requireSupervisorSignOff: boolean;
  signOffRole: string;
  speciality: string[];
  stateLimited: boolean;
  npi?: string;
  twoFAEnabled?: boolean;
  acceptingAdolescents?: boolean;
  taxId?: string;
  billingProvider?: boolean;
  practitionerAddress?: {
    state?: string;
    city?: string;
    zipCode?: string;
    zipPlusFourCode?: string;
    address1?: string;
  };
}

export interface ProfileState {
  totalProviders?: number;
  careTeamList2: any[];
  isLoading: boolean;
  todoCount: number;
  evaluationTodoCount: number;
  todosStats: ITodoStats;
  notesTodoCount: number;
  validationTodoCount: number;
  validationTodos: any[];
  adminValidationTodos: any[];
  todos: any[];
  evaluationTodos: any[];
  notesTodos: any[];
  groupsLoading: boolean;
  errorMsg: string;
  payload: {
    currentPage: number;
    totalPages: number;
    totalRecords: number;
    patientsList: IPatient[];
  };
  selectedPatient: IPatient;
  providerDetails: IProvider;
  profile: IProfile;
  selectedAppointment: IAppointment;
  demographicDetails: IDemographicDetails;
  userGroups: IUserGroup[];
  providers: IProvider[];
  providers2: IProvider[];
  domainTypes: IDomainType[];
  walletBalance: number;
  activeConnections: any[];
  chats: {
    members: {
      isLoading: boolean;
      connections: any[];
      hasMore: boolean;
      currentPage: number;
      loadingMore: boolean;
    };
    providers: {
      isLoading: boolean;
      connections: any[];
      hasMore: boolean;
      currentPage: number;
      loadingMore: boolean;
    };
    careTeam: {
      isLoading: boolean;
      connections: any[];
      hasMore: boolean;
      currentPage: number;
      loadingMore: boolean;
    };
    careNavigator: {
      isLoading: boolean;
      connections: any[];
      hasMore: boolean;
      currentPage: number;
      loadingMore: boolean;
    };
    searchResults: {
      isLoading: boolean;
      connections: any[];
    };
  };
  pastConnections: any[];
  connectionsFetchedFor: string;
  connections: {
    isLoading: boolean;
    errorMsg: string;
    connections: IConnection[];
    currentPage: number;
    totalPages: number;
    totalRecords: number;
  };
  lookupData: HistoryLookup;
  group: GroupsState;
  demographicMetadata: IDemographicData;
  memberInsurance: IMemberInsurance;
  syncedCalendars: ISyncedCalendarData;
  googleCalendarEvents: IGoogleCalendarEventData;
  referralPartners?: IReferralPartners;
}

export interface IReferralPartners {
  totalRecords?: number;
  partners?: any[];
  isLoading: boolean;
  errorMsg?: string;
}
export interface IActiveConnection {
  connectionId: any;
  messages: any;
  lastMessageTimestamp: any;
}

export enum DomainPriorities {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  MISREPORTED = 'MISREPORTED',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
  RESOLVED = 'RESOLVED',
  UNRELATED = 'UNRELATED',
}

type IDomainElementSubstanceUse = {
  currentFrequencyOfUse?: string;
  howLongUsingThisLevel?: string;
  lastUse?: string;
  methodOfUse?: string;
  unitOfUse?: string;
  [key: string]: any;
};

type IDomainElementRxInfo = {
  dose?: number;
  doseUnit?: string;
  doseFrequency?: number;
  duration?: string;
  supply?: number;
  supplyUnit?: string;
  refillable?: boolean;
  dosage?: string;
  frequency?: string;
  symptomsBeingTreated?: [];
  prescribingProvider?: string;
  confidantProviderId?: string;
  [key: string]: any;
};

type IDomainElementHistoryInfo = {
  allergies: any[];
  criminalJusticeInvolvement: boolean;
  familyMedicationConditions: string[];
  familyMentalHealthConditions: string[];
  genderIdentity: string;
  genderPronoun: string;
  hasSupportNetwork: boolean;
  medicalConditionsCurrentlyTreatedFor: string[];
  mentalHealthConditionsCurrentlyTreatedFor: string[];
  preferredPharmacy: string;
  previousOverDose: boolean;
  previousProblemsWithMedication: string;
  previousSuicideAttempt: boolean;
  previouslyDiagnosed: boolean;
  previouslyDiagnosedMedicalConditions: string[];
  previouslyDiagnosedMentalHealthConditions: string[];
  previouslyHospitalizedForPsychiatricCare: boolean;
  previouslyReceivedSubstanceUseTreatment: boolean;
  previouslySeenProvider: boolean;
  sexAssigned: string;
};

export interface IDomainElementDetail {
  importanceLevel: {
    name: DomainPriorities;
    color: string;
  };
  description?: string;
  elementName: string;
  notes: string;
  assignedAt: string;
  assignedBy: string;
  avatar: string;
  domainElementId: string;
  domainTypeId: string;
  icd10Codes?: string[];
  dataElementIcd10CodeList?: string[];
  tagMetaData: {
    interferenceInLife: boolean;
    rxDrugInfo: IDomainElementRxInfo;
    substanceUse: IDomainElementSubstanceUse;
    specification: {
      relatedToSubstanceUse: string[];
      relatedToMedication: string[];
      relatedToWithdrawal: string[];
      relatedToMedicalCondition: string[];
    };
  };
  history: any[];
}

export interface IDomainElementTagMeta {
  interferenceInLife?: boolean;
  historyInfo?: IDomainElementHistoryInfo;
  rxDrugInfo: IDomainElementRxInfo;
  substanceUse: IDomainElementSubstanceUse;
  specification?: any;
}

export interface IDomainElement {
  groupId?: string;
  item?: any;
  id: string;
  name: string;
  description: string;
  avatar: string;
  assignedAt: string;
  assignedBy: string;
  status?: string;
  icd10Codes?: string[];
  priority: {
    color: string;
    name: DomainPriorities;
  };
  importanceLevel?: {
    color: string;
    name: DomainPriorities;
  };
  notes?: string;
  history?: IDomainElement[];
  tagMetaData?: IDomainElementTagMeta;
  isNew?: boolean;
  selectedIct10code?: string;
  domainElementId?: string;
}

export interface IDomainType {
  hasDomainElements: boolean;
  relatedElements: IDomainElement[];
  requireHistory: boolean;
  typeId: string;
  typeName: string;
  typeSpec: {
    orderBy: string;
    requireDiagnosisInfo: boolean;
    requireHistoryInfo: boolean;
    requireInterferenceWithLifeInfo: boolean;
    requireNotes: boolean;
    requireRelatedToInfo: boolean;
    requireRelatedToMedicalCondition: boolean;
    requireRelatedToMedication: boolean;
    requireRelatedToSubstanceUse: boolean;
    requireRelatedToWithdrawal: boolean;
    requireRevampInfo: boolean;
    requireRxInfo: boolean;
    requireSpecification: boolean;
    requireSubstanceUse: boolean;
  };
}

export type IConnection = {
  connectionId: string;
  messages: any[];
  profilePicture?: string;
  archived?: boolean;
  channelUrl?: string;
  chatBotName?: string;
  contactNotes?: any[];
  description?: string;
  designation?: string;
  dob?: string;
  email?: string;
  firstName?: string;
  inActiveChat?: boolean;
  lastMessage?: string;
  lastMessageTimestamp?: string;
  lastMessageTimestampUnix?: number;
  lastMessageUnread?: boolean;
  lastModified?: string;
  lastName?: string;
  matchMakerId?: string;
  matchMakerName?: string;
  name?: string;
  phone?: string;
  type?: string;
  colorCode?: string;
};

export type IGetConnectionByStatusQuery = {
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  status: 'active' | 'pending' | 'inactive';
  type: 'members' | 'providers' | 'groups';
  userId: string;
};

export type IGetPaginatedConnectionsQuery = {
  userId?: string;
  status?: string;
  type?: string;
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  searchByChannelUrl?: string;
  searchByConnectionId?: string;
};

export type LookupItem = {
  name: string;
  value: string;
};

export type HistoryLookup = {
  continuousLevelOfSubstanceUse: LookupItem[];
  currentFrequencyOfSubstanceUse: LookupItem[];
  doseUnit: LookupItem[];
  genderIdentity: LookupItem[];
  genderPronoun: LookupItem[];
  importanceLevels: LookupItem[];
  lastSubstanceUse: LookupItem[];
  medicalCondition: LookupItem[];
  mentalHealthCondition: LookupItem[];
  methodsOfSubstanceUse: LookupItem[];
  sex: LookupItem[];
  supplyUnit: LookupItem[];
  unitsOfSubstanceUse: LookupItem[];
};

export interface IGroup {
  channelUrl: string;
  id: string;
  name: string;
}

export interface GroupsState {
  isLoading: boolean;
  errorMsg: string;
  groups: IGroup[];
}

export interface ILevelOfEngagement {
  id: string;
  name: string;
  description: string;
}

export interface IDemographicData {
  gender: string[];
  genderIdentities: string[];
  genderPronouns: string[];
  levelOfEngagements: ILevelOfEngagement[];
  primaryInterests: string[];
  referralSources: string[];
  isLoading: boolean;
  errorMsg: string;
}

export interface InsurancePlan {
  code: string;
  isActive: boolean;
  membersCount: number;
  name: string;
  providers: string[];
  providersDetails: {
    firstName: string;
    fullName: string;
    imageUrl: string;
    isActive: boolean;
    isBillingProvider: boolean;
    isRenderingProvider: boolean;
    lastName: string;
    nickName: string;
    notAllowedCPTCodes: string[];
    npi: string;
    role: string;
    userAccountId: string;
    _id: string;
  };
}

export interface IMemberInsuranceRecord {
  createdAt: string;
  currentInsurance: {
    code: string;
    createdAt: string;
    isActive: boolean;
    name: string;
    plans: InsurancePlan[];
    providers: string[];
    stateId: string;
    updatedAt: string;
    _id: string;
  };
  insuranceType?: string;
  noOfVisitsPerMonth: number;
  insurances: [];
  patientInsuranceData: {
    firstName: string;
    lastName: string;
    address: string;
    dob: string;
    employer: null;
    fullName: string;
    idCardNumber: string;
    idCardPhotoBack: string;
    idCardPhotoFront: string;
    primaryInsured: boolean;
    primarySubscriberName: string;
    relationshipToClient: string;
    employerGroupId: string;
    employerName: string;
    gender: string;
    memberId: string;
    memberIdPhotoBack: string;
    memberIdPhotoFront: string;
    relationshipToPrimarySubscriber: string;
  };
  insuranceEligibilityDetail?: any;
  soharSystemId?: string;
  vob?: boolean;
  insuranceEligibilityEligible?: boolean;
  insuranceEligibilityStatus?: string;
  isCopay: boolean;
  coPayAmount: number;
  rejectionNotes: string;
  status: string;
  updatedAt: string;
  userAccountId: string;
}

export interface IMemberInsurance {
  insurance: IMemberInsuranceRecord;
  isLoading: boolean;
  isSaving: boolean;
  errorMsg: string;
}

export interface ITodoStats {
  activeTodoStats: {
    chatCount: number;
    evaluationsCount: number;
    notesCount: number;
    alfieValidationCount: number;
    withoutDueDateCount: number;
    withDueDateCount: number;
    pastDueDateCount: number;
    avgCompletionTimeSignOff: string;
    avgCompletionTimeChat: string;
    avgCompletionTimeEvaluations: string;
    avgCompletionTimeNotes: string;
    signOffCount: number;
    avgCompletionTimeAlfieValidation: string;
    total: number;
  };
  completedTodoStats: {
    chatCount: number;
    evaluationsCount: number;
    notesCount: number;
    alfieValidationCount: number;
    withoutDueDateCount: number;
    withDueDateCount: number;
    signOffCount: number;
    pastDueDateCount: number;
    avgCompletionTimeSignOff: string;
    avgCompletionTimeChat: string;
    avgCompletionTimeEvaluations: string;
    avgCompletionTimeNotes: string;
    avgCompletionTimeAlfieValidation: string;
    total: number;
  };
}

export interface ICalendar {
  authToken: string | null;
  calendarSettings: {
    syncConfidantSystemToGoogle: boolean;
    syncGoogleToConfidantSystem: boolean;
    visibilityTag: string;
  };
  calenderExternalId: string | null;
  email: string;
  name: string;
  expiry: string | null;
  lastSyncAt: string | null;
  status: boolean;
  type: string;
}

export interface ISyncedCalendar {
  calendar: ICalendar;
  email: string;
}

export interface ISyncedCalendarData {
  calendars: ISyncedCalendar[];
  isLoading: boolean;
  errorMsg: string;
}

export interface IGoogleCalendarEventItem {
  id: string;
  originalTime: {
    start: string;
    end: string;
  };
  utc: {
    start: string;
    end: string;
  };
  timezone: string;
  type: string;
}

export interface IGoogleCalendarEvent {
  calenderId: string;
  events: IGoogleCalendarEventItem[];
}

export interface IGoogleCalendarEventData {
  events: IGoogleCalendarEvent[];
  isLoading: boolean;
  errorMsg: string;
}
