import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawerContent: { overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 32 },
  daySlots: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    display: 'block',
    marginBottom: 8,
  },
  select: {
    width: '100%',
  },
});
